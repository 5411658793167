@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&display=swap');
body,
html {
  font-family: 'Barlow', sans-serif;
}

.date_picker {
  text-align: center;
  color: red;
  padding-top: 4px;
  padding-bottom: 4px;
  border: none;
  border-radius: 3px;
  caret-color: transparent;
}

