.infobox-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  font-family: 'Barlow', sans-serif;
}

.infobox-row > img {
  margin-top: auto;
  padding-left: 2px;

  width: 1em;
  height: 1em;
}

.infobox-row > span,
.infobox-row > a {
  margin-left: 5px;
}

.infobox-row > span.warning {
  color: #ff0000;
}

.infobox-row > span.notabene {
  color: rgb(150, 50, 50);
  font-style: italic;
}
